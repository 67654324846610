import React from "react";
import ProjectPreview from "./project-preview";
import styles from "./project-preview-grid.module.css";
import Masonry from "react-masonry-css";

function ProjectPreviewGrid(props) {
  const projectNodes = props.nodes.sort((a, b) => {
    if (!a.categories) {
      return;
    }
    if (a.categories.length !== 0) {
      return a.categories[0].title === props.sortBy ? -1 : 1;
    }
  }); 

  return (
    <div className={styles.root}>
      <Masonry
        breakpointCols={{ default: 3, 800: 2 }}
        className={styles.brickGrid}
        columnClassName={styles.brickGridColumn}
      >
        {projectNodes && projectNodes.map(node => <ProjectPreview key={node.id} {...node} />)}
      </Masonry>
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default ProjectPreviewGrid;
