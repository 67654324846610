import {Link} from 'gatsby'
import React, {useState} from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './project-preview.module.css'

function ProjectPreview (props) {
  const [hover, toggleHover] = useState(false)

  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <div className={styles.leadMediaThumb} onMouseEnter={() => toggleHover(!hover)} onMouseLeave={() => toggleHover(!hover)}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .url()}
            alt={props.mainImage.alt}
          />
        )}
        {hover &&
        <div className={styles.projectMediaTitle}><span>{props.title}</span></div>
        }
      </div>
    </Link>
  )
}

export default ProjectPreview
